@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allerta:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500,600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
	overflow-x: hidden;
}
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
