.mobile-version{
    display:none;
}
.marquee-rtl {
    min-width: 300px;                      /* largeur de la fenêtre */
    border: 1px solid #F0F0FF;
    overflow: hidden;          
  }
  .marquee-rtl > :first-child {
    overflow-y: hidden;
    display: inline-block;                /* modèle de boîte en ligne */
    padding-right: 2em;                   /* un peu d'espace pour la transition */
    padding-left: 100%;                   /* placement à droite du conteneur */              /* pas de passage à la ligne */
    animation: defilement-rtl 90s infinite linear;
  }

  @keyframes defilement-rtl {
    0% {
      transform: translate3d(0,0,0);      /* position initiale à droite */
    }
    100% {
      transform: translate3d(-100%,0,0);  /* position finale à gauche */
    }
  }

@media (max-width: 768px) {
    .marquee-rtl > :first-child {
        max-width: 300px;
        overflow-y: visible;
        animation: defilement-rtl 60s infinite linear;
      }
    .desktop-version{
        display: none;
    }
    .mobile-version{
        display: block;
    }
    @keyframes defilement-rtl {
      0% {
        transform: translate3d(0,0,0);      /* position initiale à droite */
      }
      100% {
        transform: translate3d(-320%,0,0);  /* position finale à gauche */
      }
    }
}
